import { AxiosMethod } from "../utils/interceptors";

export function HTTPGetTasks(props: {
  email: string;
  activity_id: number;
  date: string;
}): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosMethod().post(`/task/get-all`, {
        email: props.email,
        activity_id: props.activity_id,
        date: props.date,
      });
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}
