import { Colors } from "../../utils/styles"
import { Button, Icon, IconButton, Stack, TextField } from "@mui/material"
import React from "react";
import { isMobile } from "react-device-detect";

const filters = [
    {
        color: Colors.SUBMAIN,
        colorScheme: 'secondary',
        label: 'Semua',
        type: 'all'
    },
    {
        color: Colors.INFO,
        colorScheme: 'info',
        label: 'Lembur',
        type: 'overtime'
    },
    {
        color: Colors.WARNING,
        colorScheme: 'warning',
        label: 'Izin',
        type: 'permit'
    },
    {
        color: Colors.ERROR,
        colorScheme: 'error',
        label: 'Cuti',
        type: 'leave'
    },
]

export const Header = (props: { onRefresh: any; filter: string; onFilter: any; onSearch: any }) => {
    const [search, setSearch] = React.useState("")

    const onSearch = (e: any) => {
        setSearch(e.target.value)
        props.onSearch(e.target.value)
    }

    return (
        <Stack direction={isMobile ? 'column-reverse' : 'row'} alignItems={'center'} width={'100%'} spacing={isMobile ? 3 : 1} justifyContent={'space-between'}>
            <Stack direction={'row'} alignItems={'center'} spacing={1} width={isMobile ? '100%' : '30%'}>
                {
                    filters.map((item: any, index: number) => (
                        <Button
                            key={index}
                            color={item.colorScheme}
                            size={'medium'}
                            onClick={() => props.onFilter(item)}
                            sx={{ borderRadius: 2, color: props.filter === item.type ? '#fff' : item.color, textTransform: 'capitalize', width: '30%' }}
                            variant={props.filter === item.type ? 'contained' : 'outlined'}
                        >{item.label}</Button>
                    ))
                }
            </Stack>
            <Stack direction={isMobile ? 'row-reverse' : 'row'} alignItems={'center'} justifyContent={'flex-end'} spacing={1} width={isMobile ? '100%' : '50%'}>
                {
                    isMobile ?
                        <IconButton onClick={props.onRefresh}>
                            <Icon>refresh</Icon>
                        </IconButton>
                        :
                        <Button
                            onClick={props.onRefresh}
                            startIcon={(<Icon>refresh</Icon>)}
                            color={'inherit'}
                            variant={'text'}
                        >Refresh</Button>
                }
                <TextField
                    placeholder="Cari..."
                    variant="outlined"
                    size={'small'}
                    sx={{ backgroundColor: '#ffffff80', borderRadius: 3, width: '90%' }}
                    value={search}
                    onChange={onSearch}
                    InputProps={{
                        sx: { color: '#000', borderRadius: 3 },
                        startAdornment: (<Icon fontSize={'small'} sx={{ marginRight: 1 }}>search</Icon>)
                    }}
                />
            </Stack>
        </Stack>
    )
}