import { BoxShadow, Colors } from "../../utils/styles";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Icon, Slide, Stack, Typography } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import React from "react";
import { isMobile } from "react-device-detect";
import { toast } from "react-toastify";
import { HTTPDeleteUser } from "../../servers/users";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const DeleteUserDialog = (props: { isOpen: boolean; onClose: any; data: any; onFinish: any }) => {
    const [isLoading, setLoading] = React.useState(false)

    const onSubmit = async () => {
        setLoading(true)
        try {
            await HTTPDeleteUser({ email: props.data.email })
            props.onClose()
            props.onFinish()
        } catch (error) {
            console.log(error)
            toast.error('Terjadi kesalahan ketika menghapus data!')
        }
        setLoading(false)
    }

    return (
        <React.Fragment>
            <Dialog
                open={props.isOpen}
                onClose={props.onClose}
                keepMounted
                disableScrollLock
                TransitionComponent={Transition}
                PaperProps={{
                    sx: {
                        width: '100%',
                        maxWidth: isMobile ? '100vw' : '30vw',
                        borderRadius: 5,
                    }
                }}
            >
                <DialogContent>
                    <Stack direction={'column'} spacing={1} width={'100%'} alignItems={'center'} paddingY={1}>
                        <Icon sx={{ color: Colors.ERROR, fontSize: 50 }}>cancel</Icon>
                        <Typography variant="body1" color={Colors.SUBMAIN} sx={{ textAlign: 'center' }}>Yakin ingin menghapus akun ini?</Typography>
                        <br />
                        <Stack direction={'row'} justifyContent={'center'} alignItems={'center'} spacing={1} width={'100%'}>
                            <Button
                                disabled={isLoading}
                                color={'primary'}
                                variant={'text'}
                                onClick={props.onClose}
                                sx={{ textTransform: 'capitalize', borderRadius: 3 }}
                            >Batal</Button>
                            <Button
                                disabled={isLoading}
                                color={'error'}
                                variant={'contained'}
                                onClick={onSubmit}
                                sx={{ textTransform: 'capitalize', borderRadius: 3 }}
                            >Hapus</Button>
                        </Stack>
                    </Stack>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    )
}