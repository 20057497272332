import { CENTER } from "../../utils/styles";
import { Input, Stack, TextField, Typography, Button, IconButton, Icon } from '@mui/material'
import React from "react";
import { HTTPAuthLogin } from "../../servers/auth";
import './styles.css'
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { isMobile } from 'react-device-detect'

const EmailChecker = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const LoginPage = () => {
    const navigate = useNavigate()
    const [init, setInit] = React.useState(false)
    const [showPass, setShowPass] = React.useState(true)
    const [Email, setEmail] = React.useState("")
    const [Password, setPassword] = React.useState("")
    const [isLoading, setLoading] = React.useState(false)

    const onSubmit = async () => {
        setLoading(true)
        if (Email.length === 0 && Password.length === 0) {
            toast.error('Pastikan Email dan Password terisi!')
        } else {
            if (Email.toLowerCase().match(EmailChecker)) {
                try {
                    const result = await HTTPAuthLogin({
                        email: Email.toLowerCase(),
                        password: Password
                    })
                    if (result.data.data[0].level === "staff") {
                        toast.error("Akun karyawan tidak diperkenankan untuk login!")
                    } else {
                        localStorage.setItem("isLogin", 'true')
                        localStorage.setItem("auth", JSON.stringify(result.data.data[0]))
                        navigate('/staff')
                    }
                } catch (error: any) {
                    console.log(error)
                    toast.error(error.data.message)
                }
            } else {
                toast.error('Email tidak sesuai!')
            }
        }
        setLoading(false)
    }

    React.useEffect(() => {
        const AuthChecker = () => {
            const isLogin = localStorage.getItem('isLogin')
            const auth = localStorage.getItem('auth') as string

            if (isLogin === 'true' && auth.length !== 0) {
                navigate('/staff')
            }
        }
        AuthChecker()
    }, [init])

    return (
        <main className="main" style={{ backgroundPosition: isMobile ? 'left' : 'bottom' }}>
            <div style={{ width: '100%', ...CENTER, height: '100vh', backgroundColor: '#00000050' }}>
                <div style={{ backgroundColor: '#00000050', borderRadius: 20, backdropFilter: 'blur(3px)' }}>
                    <Stack direction={'column'} gap={5} alignItems={'center'} width={isMobile ? '90vw' : '40vw'} padding={isMobile ? '20px 0' : 5}>
                        <Typography variant="h4" color="#fff" sx={{ width: isMobile ? '90%' : '100%' }}>Login</Typography>
                        <TextField
                            placeholder="Email"
                            variant="outlined"
                            sx={{ backgroundColor: '#00000080', width: isMobile ? '90%' : '100%', borderRadius: 3 }}
                            InputProps={{ sx: { color: '#fff', borderRadius: 3 } }}
                            value={Email}
                            onChange={(text) => setEmail(text.target.value)}
                        />
                        <TextField
                            type={showPass ? 'password' : 'text'}
                            placeholder="Password"
                            variant="outlined"
                            sx={{ backgroundColor: '#00000080', width: isMobile ? '90%' : '100%', borderRadius: 3 }}
                            value={Password}
                            onChange={(text) => setPassword(text.target.value)}
                            InputProps={{
                                sx: { color: '#fff', borderRadius: 3 },
                                endAdornment: (
                                    <IconButton onClick={() => setShowPass(!showPass)}>
                                        <Icon fontSize={'medium'} sx={{ color: '#fff' }}>{showPass ? 'visibility' : 'visibility_off'}</Icon>
                                    </IconButton>
                                )
                            }}
                        />
                        <Button
                            onClick={onSubmit}
                            variant="contained"
                            color="primary"
                            size="large"
                            sx={{ width: isMobile ? '90%' : '80%', borderRadius: 3 }}
                            disabled={isLoading}
                        >
                            Masuk sebagai Admin
                        </Button>
                    </Stack>
                </div>
            </div>
        </main>
    );
}

export default LoginPage;