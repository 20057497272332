import { Assets } from "../../assets";
import { Colors } from "../../utils/styles";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Icon, IconButton, MenuItem, Select, Slide, Stack, TextField, Typography } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import React from "react";
import { isMobile } from "react-device-detect";
import { toast } from "react-toastify";
import { HTTPChangePassword } from "../../servers/users";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const PasswordUserDialog = (props: { isOpen: boolean; onClose: any; data: any; onFinish: any }) => {
    const [showPass, setShowPass] = React.useState(true)
    const [isLoading, setLoading] = React.useState(false)
    const [Password, setPassword] = React.useState("")
    const [ConfirmPass, setConfirmPass] = React.useState("")

    const onClose = () => {
        props.onClose()
        setPassword("")
        setConfirmPass("")
    }

    const onSubmit = async () => {
        setLoading(true)
        if (Password.length === 0 || ConfirmPass.length === 0) {
            toast.error('Pastikan semua form terisi!')
        } else {
            try {
                if (Password !== ConfirmPass) {
                    toast.error('Password tidak sesuai!')
                } else {
                    await HTTPChangePassword({
                        email: props.data.email,
                        password: Password
                    })
                    onClose()
                    toast.success('Berhasil mengubah password!')
                    props.onFinish()
                }
            } catch (error) {
                console.log(error)
                toast.error('Terjadi kesalahan pada saat mengubah Password!')
            }
        }
        setLoading(false)
    }

    return (
        <React.Fragment>
            <Dialog
                open={props.isOpen}
                onClose={onClose}
                keepMounted
                disableScrollLock
                TransitionComponent={Transition}
                PaperProps={{
                    sx: {
                        width: '100%',
                        maxWidth: isMobile ? '100vw' : '30vw',
                        borderRadius: 5,
                    }
                }}
            >
                <DialogTitle sx={{ color: Colors.SUBMAIN }}>Ubah Password Karyawan</DialogTitle>
                <DialogContent>
                    <Stack direction={'column'} spacing={3} width={'100%'}>
                        <TextField
                            type={showPass ? 'password' : 'text'}
                            placeholder="Password"
                            variant="outlined"
                            size={'small'}
                            sx={{ backgroundColor: '#ffffff', borderRadius: 3 }}
                            value={Password}
                            onChange={(text) => setPassword(text.target.value)}
                            InputProps={{
                                sx: { color: Colors.SUBMAIN, borderRadius: 3 },
                                startAdornment: (<Icon fontSize={'small'} sx={{ marginRight: 1 }}>lock</Icon>),
                                endAdornment: (
                                    <IconButton onClick={() => setShowPass(!showPass)}>
                                        <Icon fontSize={'medium'} sx={{ color: Colors.SUBMAIN }}>{showPass ? 'visibility' : 'visibility_off'}</Icon>
                                    </IconButton>
                                )
                            }}
                        />
                        <TextField
                            type={showPass ? 'password' : 'text'}
                            placeholder="Konfirmasi Password"
                            variant="outlined"
                            size={'small'}
                            sx={{ backgroundColor: '#ffffff', borderRadius: 3 }}
                            value={ConfirmPass}
                            onChange={(text) => setConfirmPass(text.target.value)}
                            InputProps={{
                                sx: { color: Colors.SUBMAIN, borderRadius: 3 },
                                startAdornment: (<Icon fontSize={'small'} sx={{ marginRight: 1 }}>lock</Icon>),
                                endAdornment: (
                                    <IconButton onClick={() => setShowPass(!showPass)}>
                                        <Icon fontSize={'medium'} sx={{ color: Colors.SUBMAIN }}>{showPass ? 'visibility' : 'visibility_off'}</Icon>
                                    </IconButton>
                                )
                            }}
                        />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button
                        disabled={isLoading}
                        color={'error'}
                        variant={'text'}
                        onClick={onClose}
                        sx={{ textTransform: 'capitalize', borderRadius: 3 }}
                    >Batal</Button>
                    <Button
                        disabled={isLoading}
                        color={'primary'}
                        variant={'contained'}
                        onClick={onSubmit}
                        sx={{ textTransform: 'capitalize', borderRadius: 3 }}
                    >Simpan</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}