import { BoxShadow, CENTER, Colors } from "../../utils/styles";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Icon, Slide, Stack, Typography } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import React from "react";
import { isMobile } from "react-device-detect";
import { IActivity, ITask, ITasks, IUsers } from "../../interfaces";
import { HTTPGetTasks } from "../../servers/task";
import { HTTPGetActivitybyUser } from "../../servers/activity";
import { Assets } from "../../assets";
import Spinner from 'react-spinkit'
import moment from "moment";
import { ServerURL } from "../../utils/interceptors";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const DetailUserDialog = (props: { isOpen: boolean; onClose: any; data: any }) => {
    const [isLoading, setLoading] = React.useState(true)
    const [Activity, setActivity] = React.useState<IActivity>({
        picture: '',
        id: 0,
        date: '',
        clockIn: '',
        clockOut: '',
        location: '',
        latitude: 0,
        longitude: 0,
        status: '',
        isHoliday: [],
        _id: '',
    })
    const [Tasks, setTasks] = React.useState<any[]>([])

    const GetActivity = async () => {
        try {
            const result = await HTTPGetActivitybyUser({
                email: props.data.email,
                activity_id: props.data.activity_id,
            })
            setActivity(result.data[0].activities)
            await GetTasks(result.data[0].activities.id, result.data[0].activities.date)
        } catch (error) {
            console.log(error)
        }
    }

    const GetTasks = async (id: number, date: string) => {
        try {
            const result = await HTTPGetTasks({
                email: props.data.email,
                activity_id: id,
                date: date
            })
            setTasks(result.data[0].tasks)
        } catch (error) {
            console.log(error)
        }
    }

    React.useEffect(() => {
        async function Initial() {
            if (props.isOpen === true) {
                setLoading(true)
                await GetActivity()
                setLoading(false)
            }
        }
        Initial()
    }, [props.isOpen])

    return (
        <React.Fragment>
            <Dialog
                open={props.isOpen}
                onClose={props.onClose}
                keepMounted
                disableScrollLock
                TransitionComponent={Transition}
                PaperProps={{
                    sx: {
                        width: '100%',
                        maxWidth: isMobile ? '100vw' : '30vw',
                        borderRadius: 5,
                    }
                }}
            >
                <DialogTitle sx={{ color: Colors.SUBMAIN }}>Detail</DialogTitle>
                <DialogContent>
                    {
                        isLoading ?
                            <div style={{ ...CENTER, width: '100%', height: '30vh' }}>
                                <Spinner name="cube-grid" color={Colors.SUBMAIN} style={{ width: '10vh', height: '10vh' }} />
                            </div>
                            :
                            <Stack direction={'column'} spacing={3} width={'100%'}>
                                <Stack direction={'column'} spacing={1} width={'100%'}>
                                    <Typography variant="body1" color={Colors.SUBMAIN}>Foto</Typography>
                                    <img
                                        width={0}
                                        height={0}
                                        alt={'profile'}
                                        src={Activity.picture.length === 0 ? Assets.images.avatar : ServerURL + 'public/activities/' + Activity.picture}
                                        style={{
                                            width: '40%',
                                            height: 'auto',
                                            aspectRatio: 1,
                                            borderRadius: 10,
                                            objectFit: 'cover',
                                        }}
                                    ></img>
                                </Stack>
                                <Stack direction={'column'} spacing={1} width={'100%'}>
                                    <Typography variant="body1" color={Colors.SUBMAIN}>Shift</Typography>
                                    <Stack direction={'row'} alignItems={'center'} spacing={1}>
                                        <Icon fontSize={'medium'} sx={{ color: Colors.SECONDARY }}>alarm</Icon>
                                        <Typography variant="h6" color={Colors.SECONDARY}>{props.data.shift[0] + ' - ' + props.data.shift[1]}</Typography>
                                    </Stack>
                                </Stack>
                                <Stack direction={'column'} spacing={1} width={'100%'}>
                                    <Typography variant="body1" color={Colors.SUBMAIN}>Waktu Presensi</Typography>
                                    {
                                        moment(`${Activity.date} ${Activity.clockIn}`).isBefore(`${Activity.date} ${props.data.shift[0]}`) ?
                                            <Stack direction={'row'} alignItems={'center'} spacing={1}>
                                                <Icon fontSize={'medium'} sx={{ color: Colors.PRIMARY }}>schedule</Icon>
                                                <Typography variant="h5" color={Colors.PRIMARY}>{Activity.clockIn}</Typography>
                                                <Typography variant="body1" color={Colors.PRIMARY}>{'(Lebih awal)'}</Typography>
                                            </Stack>
                                            :
                                            moment(`${Activity.date} ${Activity.clockIn}`).isAfter(`${Activity.date} ${props.data.shift[0]}`) ?
                                                <Stack direction={'row'} alignItems={'center'} spacing={1}>
                                                    <Icon fontSize={'medium'} sx={{ color: Colors.ERROR }}>schedule</Icon>
                                                    <Typography variant="h5" color={Colors.ERROR}>{Activity.clockIn}</Typography>
                                                    <Typography variant="body1" color={Colors.ERROR}>{'(Telat)'}</Typography>
                                                </Stack>
                                                :
                                                moment(`${Activity.date} ${Activity.clockIn}`).isSame(`${Activity.date} ${props.data.shift[0]}`) ?
                                                    <Stack direction={'row'} alignItems={'center'} spacing={1}>
                                                        <Icon fontSize={'medium'} sx={{ color: Colors.SUCCESS }}>schedule</Icon>
                                                        <Typography variant="h5" color={Colors.SUCCESS}>{Activity.clockIn}</Typography>
                                                        <Typography variant="body1" color={Colors.SUCCESS}>{'(Tepat Waktu)'}</Typography>
                                                    </Stack>
                                                    :
                                                    null
                                    }
                                </Stack>
                                <Stack direction={'column'} spacing={1} width={'100%'}>
                                    <Typography variant="body1" color={Colors.SUBMAIN}>Lokasi Presensi</Typography>
                                    <Box sx={{ width: '100%', borderRadius: 3, backgroundColor: '#fff', padding: 2, ...BoxShadow }}>
                                        <Stack direction={'row'} alignItems={'flex-start'} spacing={1}>
                                            <Icon fontSize={'small'} sx={{ color: Colors.PRIMARY }}>place</Icon>
                                            <Typography variant="body2" color={Colors.SECONDARY}>{Activity.location}</Typography>
                                        </Stack>
                                    </Box>
                                </Stack>
                                <Stack direction={'column'} spacing={1} width={'100%'}>
                                    <Typography variant="body1" color={Colors.SUBMAIN}>Daftar Tugas</Typography>
                                    {
                                        Tasks.map((item: ITask, index: number) => (
                                            <Box key={index} sx={{ width: '100%', borderRadius: 3, backgroundColor: '#fff', padding: 2, ...BoxShadow }}>
                                                <Stack direction={'row'} alignItems={'center'} width={'100%'} justifyContent={'space-between'}>
                                                    <Stack direction={'column'} spacing={0} sx={{ paddingLeft: 1, borderLeft: `3px solid ${item.priority === 'low' ? Colors.PRIMARY : Colors.ERROR}` }}>
                                                        <Typography color={'#000'} variant={'body2'}><b>{item.task}</b></Typography>
                                                        <Typography color={item.priority === 'low' ? Colors.PRIMARY : Colors.ERROR} variant={'caption'}>{item.priority === 'low' ? 'Normal' : 'Mendesak'}</Typography>
                                                    </Stack>
                                                    <Button
                                                        size={'small'}
                                                        color={item.status === 'progress' ? 'primary' : 'success'}
                                                        variant={'contained'}
                                                        sx={{ textTransform: 'capitalize', color: '#fff' }}
                                                    >{item.status === 'progress' ? 'Berlangsung' : 'Selesai'}</Button>
                                                </Stack>
                                            </Box>
                                        ))
                                    }
                                </Stack>
                            </Stack>
                    }
                </DialogContent>
                <DialogActions>
                    <Button
                        color={'primary'}
                        variant={'text'}
                        onClick={props.onClose}
                        sx={{ textTransform: 'capitalize', borderRadius: 3 }}
                    >Tutup</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}