import { AddUserDialog } from '../../components/dialogs/addUser'
import { DeleteUserDialog } from '../../components/dialogs/deleteUser'
import { DetailUserDialog } from '../../components/dialogs/detailUser'
import { UpdateUserDialog } from '../../components/dialogs/updateUser'
import { SideBar } from '../../components/navigation/sidebar'
import { Header } from '../../components/staff/header'
import { BoxShadow, CENTER, Colors } from '../../utils/styles'
import { Box, Button, Grid, Icon, ListItemIcon, ListItemText, Menu, MenuItem, Stack, TextField, Toolbar, Typography } from '@mui/material'
import React from 'react'
import Spinner from 'react-spinkit'
import { PasswordUserDialog } from '../../components/dialogs/passwordUser'
import { HTTPGetUsers } from '../../servers/users'
import { useNavigate } from 'react-router'
import { HTTPGetActivities } from '../../servers/activity'
import moment from 'moment'
import { Assets } from '../../assets'
import { IUsers } from '../../interfaces'
import { StaffCard } from '../../components/staff/staffCard'
import { isMobile } from 'react-device-detect'

const filters = [
    { color: Colors.SECONDARY, label: 'Belum Hadir', type: 'absent' },
    { color: Colors.MAIN, label: 'Hadir', type: 'present' },
    { color: Colors.SUCCESS, label: 'Selesai', type: 'done' },
    { color: Colors.INFO, label: 'Lembur', type: 'overtime' },
    { color: Colors.WARNING, label: 'Izin', type: 'permit' },
    { color: Colors.ERROR, label: 'Cuti', type: 'leave' },
]

const StaffPage = () => {
    const navigate = useNavigate()
    const [init, setInit] = React.useState(false)
    const [UserList, setUserList] = React.useState<any[]>([])
    const [UserBackup, setUserBackup] = React.useState<any[]>([])
    const [isLoading, setLoading] = React.useState(true)
    const [isAddUser, setAddUser] = React.useState(false)
    const [isUpdateUser, setUpdateUser] = React.useState(false)
    const [isDetailUser, setDetailUser] = React.useState(false)
    const [isPasswordUser, setPasswordUser] = React.useState(false)
    const [isDeleteUser, setDeleteUser] = React.useState(false)
    const [date, setDate] = React.useState<any>(moment())
    const [SelectedUser, setSelectedUser] = React.useState<any>({})
    const [isMenuUser, setMenuUser] = React.useState<null | HTMLElement>(null);
    const openMenu = Boolean(isMenuUser);
    const [isFilterUser, setFilterUser] = React.useState<null | HTMLElement>(null);
    const openFilter = Boolean(isFilterUser);

    const AuthChecker = () => {
        const isLogin = localStorage.getItem('isLogin')
        const auth = localStorage.getItem('auth')

        if (isLogin === undefined || isLogin === null || isLogin === 'false') {
            navigate('/')
        }
        if (auth === undefined || auth === null || auth === 'false') {
            navigate('/')
        }
    }

    const onMenuOpen = React.useCallback((event: any, data: IUsers) => {
        const shift = {
            clockIn: data.shift[0],
            clockOut: data.shift[1]
        }

        setSelectedUser({
            username: data.username,
            email: data.email,
            phone: data.phone,
            position: data.position,
            shift: JSON.stringify(shift),
            address: data.address
        })

        setMenuUser(event.currentTarget);
    }, [isMenuUser]);

    const onFilterOpen = React.useCallback((event: any) => {
        setFilterUser(event.currentTarget);
    }, [isFilterUser]);

    const onDetailUser = React.useCallback((data: IUsers) => {
        if (data.activities[0].status !== 'absent') {
            setSelectedUser({
                email: data.email,
                activity_id: data.activities[0].id,
                shift: data.shift
            })
            setDetailUser(true)
        }
    }, [isDetailUser])

    const onUpdateUser = React.useCallback(() => {
        setMenuUser(null)
        setUpdateUser(true)
    }, [isUpdateUser])

    const onPasswordUser = React.useCallback(() => {
        setMenuUser(null)
        setPasswordUser(true)
    }, [isPasswordUser])

    const onDeleteUser = React.useCallback(() => {
        setMenuUser(null)
        setDeleteUser(true)
    }, [isDeleteUser])

    const GetUsers = async () => {
        try {
            const users = await HTTPGetUsers()
            const userData: any[] = users.data
            const activities = await HTTPGetActivities({ date: moment().format('YYYY-M-D') })
            const activityData: any[] = activities.data

            const final = userData.map(item => {
                const merged = activityData.find(value => value.user_email === item.email);
                return {
                    ...item,
                    ...merged
                };
            });
            setUserList(final)
            setUserBackup(final)
        } catch (error) {
            console.log(error)
        }
    }

    const onDate = async (date: any) => {
        setLoading(true)
        try {
            const users = await HTTPGetUsers()
            const userData: any[] = users.data
            const activities = await HTTPGetActivities({ date: moment(date).format('YYYY-M-D') })
            const activityData: any[] = activities.data

            const final = userData.map(item => {
                const merged = activityData.find(value => value.user_email === item.email);
                return {
                    ...item,
                    ...merged
                };
            });
            setUserList(final)
            setUserBackup(final)
        } catch (error) {
            console.log(error)
        }
        setLoading(false)
    }

    const onSearch = (e: string) => {
        const filtered = UserBackup.filter((item) => item.username.toLowerCase().includes(e.toLowerCase()))
        setUserList(filtered)
    }

    const onFilter = (e: any) => {
        const filtered = UserBackup.filter((item) => item.activities[0].status === e.type)
        setUserList(filtered)
        setFilterUser(null)
    }

    const onRefresh = async () => {
        setLoading(true)
        await GetUsers()
        setDate(moment())
        setLoading(false)
    }

    React.useEffect(() => {
        async function Initial() {
            AuthChecker()
            await GetUsers()
            setLoading(false)
        }
        Initial()
    }, [init])

    return (
        <main style={{ backgroundColor: '#f7f7ff', minHeight: '100vh', width: '100%' }}>
            <SideBar page={'staff'} title={'Daftar Karyawan'} />
            <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                <Box sx={{ width: isMobile ? '100%' : `calc(100% - 240px)` }}>
                    <Toolbar />
                    <div style={{ width: '100%', padding: 30 }}>
                        <Header
                            addUser={() => setAddUser(true)}
                            filter={onFilterOpen}
                            date={date}
                            onDate={onDate}
                            onRefresh={onRefresh}
                            onSearch={onSearch}
                        />
                        {
                            isLoading ?
                                <div style={{ ...CENTER, width: '100%', height: '50vh' }}>
                                    <Spinner name="cube-grid" color={Colors.SUBMAIN} style={{ width: '15vh', height: '15vh' }} />
                                </div>
                                :
                                <Box sx={{ flexGrow: 1, marginTop: 3 }}>
                                    {
                                        UserList.length === 0 ?
                                            <Stack direction={"column"} width={'100%'} spacing={3} height={'60vh'} alignItems={'center'} justifyContent={'center'}>
                                                <Icon sx={{ fontSize: '15vh', color: Colors.SECONDARY }}>person_outline</Icon>
                                                <Typography variant="h5" color={Colors.SECONDARY}>Tidak ada Karyawan</Typography>
                                            </Stack>
                                            :
                                            <Grid container spacing={3}>
                                                {
                                                    UserList.map((item: any, index: number) => (
                                                        <Grid item md={3} key={index}>
                                                            <StaffCard item={item} onDetailUser={onDetailUser} onMenuOpen={onMenuOpen} />
                                                        </Grid>
                                                    ))
                                                }
                                            </Grid>
                                    }
                                </Box>
                        }
                    </div>
                </Box>
            </div>
            <AddUserDialog isOpen={isAddUser} onClose={() => setAddUser(false)} onFinish={GetUsers} />
            <UpdateUserDialog isOpen={isUpdateUser} onClose={() => setUpdateUser(false)} data={SelectedUser} onFinish={GetUsers} />
            <PasswordUserDialog isOpen={isPasswordUser} onClose={() => setPasswordUser(false)} data={SelectedUser} onFinish={GetUsers} />
            <DetailUserDialog isOpen={isDetailUser} onClose={() => setDetailUser(false)} data={SelectedUser} />
            <DeleteUserDialog isOpen={isDeleteUser} onClose={() => setDeleteUser(false)} data={SelectedUser} onFinish={GetUsers} />
            <Menu
                anchorEl={isMenuUser}
                open={openMenu}
                onClose={() => setMenuUser(null)}
                keepMounted
                disableScrollLock
            >
                <MenuItem onClick={onUpdateUser}>
                    <ListItemIcon>
                        <Icon fontSize="small" sx={{ color: Colors.WARNING }}>edit</Icon>
                    </ListItemIcon>
                    <ListItemText>Ubah Profil</ListItemText>
                </MenuItem>
                <MenuItem onClick={onPasswordUser}>
                    <ListItemIcon>
                        <Icon fontSize="small" sx={{ color: Colors.WARNING }}>lock</Icon>
                    </ListItemIcon>
                    <ListItemText>Ubah Password</ListItemText>
                </MenuItem>
                <MenuItem onClick={onDeleteUser}>
                    <ListItemIcon>
                        <Icon fontSize="small" sx={{ color: Colors.ERROR }}>delete</Icon>
                    </ListItemIcon>
                    <ListItemText>Hapus Akun</ListItemText>
                </MenuItem>
            </Menu>
            <Menu
                anchorEl={isFilterUser}
                open={openFilter}
                onClose={() => setFilterUser(null)}
                keepMounted
                disableScrollLock
            >
                {
                    filters.map((item, index) => (
                        <MenuItem key={index} onClick={() => onFilter(item)}>
                            <ListItemIcon>
                                <Icon sx={{ color: item.color, fontSize: 15 }}>circle</Icon>
                            </ListItemIcon>
                            <ListItemText>{item.label}</ListItemText>
                        </MenuItem>
                    ))
                }
            </Menu>
        </main>
    )
}

export default StaffPage;