import { AxiosMethod } from "../utils/interceptors";

export function HTTPGetUsers(): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosMethod().get(`/user/get-all`);
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPGetUserbyEmail(props: { email: string }): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosMethod().post("/user/get", {
        email: props.email,
      });
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPChangePassword(props: {
  email: string;
  password: string;
}): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosMethod().put("/user/update-password", {
        email: props.email,
        password: props.password,
      });
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPUpdateUser(props: {
  email: string;
  username: string;
  phone: string;
  position: string;
  address: string;
  shift: any[];
}): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosMethod().put("/user/update", {
        email: props.email,
        username: props.username,
        phone: props.phone,
        position: props.position,
        address: props.address,
        shift: props.shift,
      });
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPDeleteUser(props: { email: string }): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosMethod().delete(
        `/user/delete?email=${props.email}`
      );
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}
