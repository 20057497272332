import { AcceptMailDialog } from '../../components/dialogs/acceptMail'
import { RejectMailDialog } from '../../components/dialogs/rejectMail'
import { Header } from '../../components/mail/header'
import { SideBar } from '../../components/navigation/sidebar'
import { BoxShadow, CENTER, Colors } from '../../utils/styles'
import { Box, Button, Icon, IconButton, Stack, TextField, Toolbar, Typography } from '@mui/material'
import React from 'react'
import Spinner from 'react-spinkit'
import { DetailMailDialog } from '../../components/dialogs/detailMail'
import { useNavigate } from 'react-router'
import { HTTPGetMails } from '../../servers/mail'
import { IMail } from '../../interfaces'
import moment from 'moment'
import { isMobile } from 'react-device-detect'

const MailPage = () => {
    const [init, setInit] = React.useState(false)
    const [MailList, setMailList] = React.useState<any[]>([])
    const [MailBackup, setMailBackup] = React.useState<any[]>([])
    const [isLoading, setLoading] = React.useState(true)
    const [isDetail, setDetail] = React.useState(false)
    const [isAccept, setAccept] = React.useState(false)
    const [isReject, setReject] = React.useState(false)
    const [SelectedMail, setSelectedMail] = React.useState<any>({})
    const [filterType, setFilterType] = React.useState("all")
    const navigate = useNavigate()

    const AuthChecker = () => {
        const isLogin = localStorage.getItem('isLogin')
        const auth = localStorage.getItem('auth')

        if (isLogin === undefined || isLogin === null || isLogin === 'false') {
            navigate('/')
        }
        if (auth === undefined || auth === null || auth === 'false') {
            navigate('/')
        }
    }

    const onModalClose = () => {
        setDetail(false)
        setAccept(false)
        setReject(false)
        setSelectedMail({})
    }

    const onDetail = React.useCallback((data: any) => {
        setSelectedMail(data)
        setTimeout(() => {
            setDetail(true)
        }, 100)
    }, [SelectedMail, isDetail])

    const onAccept = React.useCallback((data: any) => {
        setSelectedMail(data)
        setTimeout(() => {
            setAccept(true)
        }, 100)
    }, [SelectedMail, isAccept])

    const onReject = React.useCallback((data: any) => {
        setSelectedMail(data)
        setTimeout(() => {
            setReject(true)
        }, 100)
    }, [SelectedMail, isReject])

    const GetMails = async () => {
        try {
            const result = await HTTPGetMails()
            setMailList(result.data)
            setMailBackup(result.data)
        } catch (error) {
            console.log(error)
        }
    }

    const onRefresh = async () => {
        setLoading(true)
        await GetMails()
        setLoading(false)
    }

    const onSearch = (e: string) => {
        const filtered = MailBackup.filter((item) => item.username.toLowerCase().includes(e.toLowerCase()))
        setMailList(filtered)
    }

    const onFilter = (e: any) => {
        setFilterType(e.type)
        if (e.type === 'all') {
            setMailList(MailBackup)
        } else {
            const filtered = MailBackup.filter((item) => item.type === e.type)
            setMailList(filtered)
        }
    }


    React.useEffect(() => {
        async function Initial() {
            AuthChecker()
            setLoading(true)
            await GetMails()
            setLoading(false)
        }
        Initial()
    }, [init])

    return (
        <main style={{ backgroundColor: '#f7f7ff', minHeight: '100vh', width: '100%' }}>
            <SideBar page={'mail'} title={'Daftar Pengajuan'} />
            <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                <Box sx={{ width: isMobile ? '100%' : `calc(100% - 240px)` }}>
                    <Toolbar />
                    <div style={{ width: '100%', padding: 30 }}>
                        <Header onRefresh={onRefresh} filter={filterType} onFilter={onFilter} onSearch={onSearch} />
                        {
                            isLoading ?
                                <div style={{ ...CENTER, width: '100%', height: '50vh' }}>
                                    <Spinner name="cube-grid" color={Colors.SUBMAIN} style={{ width: '15vh', height: '15vh' }} />
                                </div>
                                :
                                <div>
                                    {
                                        MailList.length === 0 ?
                                            <Stack direction={"column"} width={'100%'} spacing={3} height={'60vh'} alignItems={'center'} justifyContent={'center'}>
                                                <Icon sx={{ fontSize: '15vh', color: Colors.SECONDARY }}>drafts</Icon>
                                                <Typography variant="h5" color={Colors.SECONDARY}>Tidak ada Pengajuan</Typography>
                                            </Stack>
                                            :
                                            <Stack direction={'column'} width={'100%'} spacing={3} marginTop={3}>
                                                {
                                                    MailList.map((item: IMail, index: number) => (
                                                        <Box
                                                            key={index}
                                                            sx={{
                                                                width: '100%',
                                                                backgroundColor: '#fff',
                                                                borderRadius: 3,
                                                                ...BoxShadow,
                                                                transform: 'scale(1)',
                                                                transition: 'all .3s',
                                                                '&:hover': {
                                                                    transform: 'scale(1.03)',
                                                                    transition: 'all .3s',
                                                                    cursor: 'pointer'
                                                                }
                                                            }}
                                                        >
                                                            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} padding={isMobile ? '10px 5px 10px 20px' : 3} width={'100%'}>
                                                                <Stack onClick={() => onDetail(item)} direction={'row'} alignItems={'center'} spacing={2} width={isMobile ? '70%' : '30%'}>
                                                                    {
                                                                        isMobile ? null :
                                                                            <Typography variant="body1" color={'#ababab'}>{moment(item.date).format('D MMM')}</Typography>
                                                                    }
                                                                    {
                                                                        item.type === 'overtime' ?
                                                                            <IconButton color='info' sx={{ backgroundColor: Colors.INFO + '30' }}>
                                                                                <Icon fontSize={isMobile ? 'medium' : 'large'} sx={{ color: Colors.INFO }}>schedule</Icon>
                                                                            </IconButton>
                                                                            :
                                                                            item.type === 'permit' ?
                                                                                <IconButton color='warning' sx={{ backgroundColor: Colors.WARNING + '30' }}>
                                                                                    <Icon fontSize={isMobile ? 'medium' : 'large'} sx={{ color: Colors.WARNING }}>description</Icon>
                                                                                </IconButton>
                                                                                :
                                                                                item.type === 'leave' ?
                                                                                    <IconButton color='warning' sx={{ backgroundColor: Colors.ERROR + '30' }}>
                                                                                        <Icon fontSize={isMobile ? 'medium' : 'large'} sx={{ color: Colors.ERROR }}>beach_access</Icon>
                                                                                    </IconButton>
                                                                                    :
                                                                                    <IconButton color='warning' sx={{ backgroundColor: Colors.SECONDARY + '30' }}>
                                                                                        <Icon fontSize={isMobile ? 'small' : 'large'} sx={{ color: Colors.SECONDARY }}>question_mark</Icon>
                                                                                    </IconButton>
                                                                    }
                                                                    {
                                                                        isMobile ?
                                                                            <Stack direction={'column'}>
                                                                                <Typography variant="body1" color={Colors.SUBMAIN}>{item.username}</Typography>
                                                                                <Typography variant="body2" color={Colors.SUBMAIN}>
                                                                                    <span style={{ color: '#ababab' }}>{moment(item.date).format('YYYY-MM-DD') + " | "}</span>
                                                                                    <b>{
                                                                                        item.type === 'overtime' ? 'Lembur' :
                                                                                            item.type === 'permit' ? 'Izin' :
                                                                                                item.type === 'leave' ? 'Cuti' :
                                                                                                    'Tanpa Keterangan'
                                                                                    }</b>
                                                                                </Typography>
                                                                            </Stack>
                                                                            :
                                                                            <Typography variant="body1" color={Colors.SUBMAIN}>{item.username}</Typography>
                                                                    }
                                                                </Stack>
                                                                {
                                                                    isMobile ? null :
                                                                        <Stack onClick={() => onDetail(item)} direction={'row'} alignItems={'center'} spacing={2} width={'40%'}>
                                                                            <Typography variant="body1" color={Colors.SUBMAIN}>
                                                                                <b>{
                                                                                    item.type === 'overtime' ? 'Lembur' :
                                                                                        item.type === 'permit' ? 'Izin' :
                                                                                            item.type === 'leave' ? 'Cuti' :
                                                                                                'Tanpa Keterangan'
                                                                                }</b>
                                                                            </Typography>
                                                                            <Typography
                                                                                variant="body1"
                                                                                color={Colors.SUBMAIN}
                                                                                sx={{
                                                                                    width: '90%',
                                                                                    whiteSpace: 'nowrap',
                                                                                    overflow: 'hidden',
                                                                                    textOverflow: 'ellipsis'
                                                                                }}
                                                                            >{item.reason}</Typography>
                                                                        </Stack>
                                                                }
                                                                {
                                                                    item.status === 'accept' ?
                                                                        <div style={{ width: '30%', display: 'flex', justifyContent: 'flex-end' }}>
                                                                            {
                                                                                isMobile ?
                                                                                    <IconButton color={'success'}>
                                                                                        <Icon fontSize={isMobile ? 'medium' : 'small'} sx={{ color: Colors.SUCCESS }}>check_circle_outline</Icon>
                                                                                    </IconButton>
                                                                                    :
                                                                                    <Button
                                                                                        color={'success'}
                                                                                        size={'medium'}
                                                                                        sx={{ borderRadius: 2, color: Colors.SUCCESS, textTransform: 'capitalize' }}
                                                                                        variant={'outlined'}
                                                                                        startIcon={(<Icon fontSize={'small'} sx={{ color: Colors.SUCCESS }}>check_circle_outline</Icon>)}
                                                                                    >Telah disetujui</Button>
                                                                            }
                                                                        </div>
                                                                        :
                                                                        item.status === 'reject' ?
                                                                            <div style={{ width: '30%', display: 'flex', justifyContent: 'flex-end' }}>
                                                                                {
                                                                                    isMobile ?
                                                                                        <IconButton color={'error'}>
                                                                                            <Icon fontSize={isMobile ? 'medium' : 'small'} sx={{ color: Colors.ERROR }}>block</Icon>
                                                                                        </IconButton>
                                                                                        :
                                                                                        <Button
                                                                                            color={'error'}
                                                                                            size={'medium'}
                                                                                            sx={{ borderRadius: 2, color: Colors.ERROR, textTransform: 'capitalize' }}
                                                                                            variant={'outlined'}
                                                                                            startIcon={(<Icon fontSize={'small'} sx={{ color: Colors.ERROR }}>block</Icon>)}
                                                                                        >Telah Ditolak</Button>
                                                                                }
                                                                            </div>
                                                                            :
                                                                            item.status === 'waiting' ?
                                                                                <div style={{ width: '30%' }}>
                                                                                    {
                                                                                        isMobile ?
                                                                                            <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                                                                                <IconButton color={'warning'} onClick={() => onDetail(item)}>
                                                                                                    <Icon fontSize={isMobile ? 'medium' : 'small'} sx={{ color: Colors.WARNING }}>help_outline</Icon>
                                                                                                </IconButton>
                                                                                            </div>
                                                                                            :
                                                                                            <Stack direction={'row'} alignItems={'center'} justifyContent={'flex-end'} spacing={1} width={'100%'}>
                                                                                                <Button
                                                                                                    onClick={() => onAccept(item)}
                                                                                                    color={'success'}
                                                                                                    size={'medium'}
                                                                                                    sx={{ borderRadius: 2, color: '#fff', textTransform: 'capitalize' }}
                                                                                                    variant={'contained'}
                                                                                                    startIcon={(<Icon fontSize={'small'} sx={{ color: '#fff' }}>check_circle_outline</Icon>)}
                                                                                                >Setujui</Button>
                                                                                                <Button
                                                                                                    onClick={() => onReject(item)}
                                                                                                    color={'error'}
                                                                                                    size={'medium'}
                                                                                                    sx={{ borderRadius: 2, color: '#fff', textTransform: 'capitalize' }}
                                                                                                    variant={'contained'}
                                                                                                    startIcon={(<Icon fontSize={'small'} sx={{ color: '#fff' }}>block</Icon>)}
                                                                                                >Tolak</Button>
                                                                                            </Stack>
                                                                                    }
                                                                                </div>
                                                                                : null
                                                                }
                                                            </Stack>
                                                        </Box>
                                                    )).reverse()
                                                }
                                            </Stack>
                                    }
                                </div>
                        }
                    </div>
                </Box>
            </div >
            <DetailMailDialog isOpen={isDetail} onClose={onModalClose} data={SelectedMail} onFinish={GetMails} />
            <AcceptMailDialog isOpen={isAccept} onClose={onModalClose} data={SelectedMail} onFinish={GetMails} />
            <RejectMailDialog isOpen={isReject} onClose={onModalClose} data={SelectedMail} onFinish={GetMails} />
        </main >
    )
}

export default MailPage;