import { Box, Button, Icon, Stack, Typography } from "@mui/material"
import { Assets } from "../../assets"
import { ServerURL } from "../../utils/interceptors";
import { BoxShadow, Colors } from "../../utils/styles"

export const StaffCard = (props: { item: any; onDetailUser: any; onMenuOpen: any }) => {
    return (
        <Box
            sx={{
                ...BoxShadow,
                backgroundColor: '#fff',
                borderRadius: 5,
                width: '100%',
                transform: 'scale(1)',
                transition: 'all .3s',
                '&:hover': {
                    transform: 'scale(1.05)',
                    transition: 'all .3s',
                }
            }}
        >
            <Stack direction={'column'} alignItems={'center'} spacing={1} width={'100%'} padding={3}>
                <img
                    alt={'profile'}
                    src={props.item.picture.length === 0 ? Assets.images.avatar : ServerURL + 'public/profiles/' + props.item.picture}
                    style={{
                        width: '35%',
                        height: 'auto',
                        aspectRatio: 1,
                        borderRadius: 100,
                        border: `1px solid ${Colors.MAIN}`,
                        objectFit: 'cover',
                    }}
                />
                <div style={{ width: '100%', margin: '30px 0' }}>
                    <Typography variant="h6" color={Colors.SUBMAIN} textAlign={'center'} width={'100%'}>{props.item.username}</Typography>
                    <Typography variant="body1" color={Colors.MAIN} textAlign={'center'} width={'100%'}>{props.item.position}</Typography>
                </div>
                {
                    props.item.activities[0].status === 'absent' ?
                        <Button size={'medium'} sx={{ backgroundColor: '#565656', borderRadius: 2, width: '100%', color: '#fff' }} variant={'contained'}>Belum Hadir</Button> :
                        props.item.activities[0].status === 'present' ?
                            <Button color={'primary'} size={'medium'} sx={{ borderRadius: 2, width: '100%', color: '#fff' }} variant={'contained'}>Sedang Bekerja</Button> :
                            props.item.activities[0].status === 'break' ?
                                <Button color={'warning'} size={'medium'} sx={{ borderRadius: 2, width: '100%', color: '#fff' }} variant={'contained'}>Istirahat</Button> :
                                props.item.activities[0].status === 'done' ?
                                    <Button color={'success'} size={'medium'} sx={{ borderRadius: 2, width: '100%', color: '#fff' }} variant={'contained'}>Selesai</Button> :
                                    props.item.activities[0].status === 'overtime' ?
                                        <Button color={'secondary'} size={'medium'} sx={{ borderRadius: 2, width: '100%', color: '#fff' }} variant={'contained'}>Lembur</Button> :
                                        props.item.activities[0].status === 'leave' ?
                                            <Button color={'error'} size={'medium'} sx={{ borderRadius: 2, width: '100%', color: '#fff' }} variant={'contained'}>Cuti</Button> :
                                            props.item.activities[0].status === 'permit' ?
                                                <Button color={'info'} size={'medium'} sx={{ borderRadius: 2, width: '100%', color: '#fff' }} variant={'contained'}>Izin</Button> :
                                                props.item.activities[0].status === 'holiday' ?
                                                    <Button size={'medium'} sx={{ backgroundColor: "#f953d5", borderRadius: 2, width: '100%', color: '#fff' }} variant={'contained'}>Hadir</Button> :
                                                    <Button size={'medium'} sx={{ backgroundColor: "#565656", borderRadius: 2, width: '100%', color: '#fff' }} variant={'contained'}>Tanpa Keterangan</Button>
                }
                <Stack direction={'row'} alignItems={"stretch"} spacing={1} width={'100%'} justifyContent={'space-between'} sx={{ color: '#fff' }}>
                    <Button
                        onClick={() => props.onDetailUser(props.item)}
                        color={'primary'}
                        size={'medium'}
                        disabled={props.item.activities[0].status === 'absent'}
                        sx={{
                            borderRadius: 2,
                            width: '75%',
                            color: Colors.PRIMARY,
                            textTransform: 'capitalize'
                        }}
                        variant={'outlined'}
                    >Detail</Button>
                    <Button onClick={(e) => props.onMenuOpen(e, props.item)} color={'inherit'} size={'medium'} sx={{ borderRadius: 2, width: '20%', border: '1px solid #00000050' }} variant={'outlined'}>
                        <Icon fontSize={'medium'} sx={{ color: Colors.SUBMAIN }}>more_horiz</Icon>
                    </Button>
                </Stack>
            </Stack>
        </Box>
    )
}