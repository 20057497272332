import { Button, Icon, Stack, TextField, IconButton } from "@mui/material"
import { DatePicker, MobileDatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import React from "react";
import { isMobile } from "react-device-detect";

export const Header = (props: { addUser: any; filter: any; date: any; onRefresh: any; onSearch: any; onDate: any }) => {
    const [search, setSearch] = React.useState("")

    const onSearch = (e: any) => {
        setSearch(e.target.value)
        props.onSearch(e.target.value)
    }

    return (
        <Stack direction={isMobile ? 'column' : 'row'} alignItems={isMobile ? 'flex-end' : 'center'} width={'100%'} justifyContent={'space-between'} spacing={isMobile ? 5 : 0}>
            <Stack direction={'row'} alignItems={'center'} spacing={isMobile ? 1 : 3} width={isMobile ? '100%' : '65%'}>
                <TextField
                    placeholder="Cari..."
                    variant="outlined"
                    size={'small'}
                    sx={{ backgroundColor: '#ffffff80', width: isMobile ? '80%' : '40%', borderRadius: 3 }}
                    value={search}
                    onChange={onSearch}
                    InputProps={{
                        sx: { color: '#000', borderRadius: 3 },
                        startAdornment: (<Icon fontSize={'small'} sx={{ marginRight: 1 }}>search</Icon>)
                    }}
                />
                {
                    isMobile ?
                        <IconButton onClick={props.filter}>
                            <Icon>filter_list</Icon>
                        </IconButton>
                        :
                        <Button
                            onClick={props.filter}
                            startIcon={(<Icon>filter_list</Icon>)}
                            color={'inherit'}
                            variant={'text'}
                        >Filter</Button>
                }
                {
                    isMobile ?
                        <IconButton onClick={props.onRefresh}>
                            <Icon>refresh</Icon>
                        </IconButton>
                        :
                        <Button
                            onClick={props.onRefresh}
                            startIcon={(<Icon>refresh</Icon>)}
                            color={'inherit'}
                            variant={'text'}
                        >Refresh</Button>
                }
                {
                    isMobile ? null :
                        <MobileDatePicker
                            value={props.date}
                            onChange={(date) => props.onDate(date)}
                            maxDate={moment()}
                            sx={{ borderRadius: 3 }}
                            slotProps={{ textField: { size: 'small' } }}
                            closeOnSelect={true}
                        />
                }
            </Stack>
            <Stack direction={'row'} alignItems={'center'} spacing={3} space-beteween={'space-between'}>
                {
                    isMobile ?
                        <MobileDatePicker
                            value={props.date}
                            onChange={(date) => props.onDate(date)}
                            maxDate={moment()}
                            sx={{ borderRadius: 3 }}
                            slotProps={{ textField: { size: 'small' } }}
                            closeOnSelect={true}
                        />
                        : <div></div>
                }
                <Button
                    onClick={props.addUser}
                    color={'primary'}
                    size={'medium'}
                    sx={{ borderRadius: 2, color: '#fff', textTransform: 'capitalize' }}
                    variant={'contained'}
                    startIcon={(<Icon fontSize={'small'} sx={{ color: '#fff' }}>add</Icon>)}
                >{isMobile ? 'Tambah' : 'Tambah Karyawan'}</Button>
            </Stack>
        </Stack>
    )
}