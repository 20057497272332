import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Icon, Stack } from '@mui/material';
import { Colors } from '../../utils/styles';
import { Assets } from '../../assets';
import { LogoutDialog } from '../dialogs/logout';
import { isMobile } from 'react-device-detect';
import moment from 'moment';
import { useNavigate } from 'react-router';

const SideContent = (props: { page: string; navigate: any; onLogout: any }) => (
    <div>
        <Stack direction={'column'} spacing={5} width={'100%'} alignItems={'center'} padding={3}>
            <img alt={'logo'} src={Assets.icons.logo} style={{ width: '80%', aspectRatio: 1, height: 'auto', objectFit: 'contain' }} />
        </Stack>
        <Divider />
        <List sx={{ paddingX: 1 }}>
            <ListItem disablePadding sx={{ backgroundColor: props.page === 'staff' ? Colors.MAIN : '#fff', borderRadius: 3 }}>
                <ListItemButton onClick={() => props.navigate('/staff')}>
                    <ListItemIcon>
                        <Icon sx={{ color: props.page === 'staff' ? '#fff' : Colors.SECONDARY }}>groups</Icon>
                    </ListItemIcon>
                    <ListItemText
                        sx={{ color: props.page === 'staff' ? '#fff' : Colors.SECONDARY }}
                        primary={'Karyawan'}
                    />
                </ListItemButton>
            </ListItem>
            <ListItem disablePadding sx={{ backgroundColor: props.page === 'mail' ? Colors.MAIN : '#fff', borderRadius: 3, marginTop: 1 }}>
                <ListItemButton onClick={() => props.navigate('/mail')}>
                    <ListItemIcon>
                        <Icon sx={{ color: props.page === 'mail' ? '#fff' : Colors.SECONDARY }}>email</Icon>
                    </ListItemIcon>
                    <ListItemText
                        sx={{ color: props.page === 'mail' ? '#fff' : Colors.SECONDARY }}
                        primary={'Pengajuan'}
                    />
                </ListItemButton>
            </ListItem>
        </List>
        <Divider />
        <List sx={{ paddingX: 1 }}>
            <ListItem disablePadding>
                <ListItemButton onClick={props.onLogout}>
                    <ListItemIcon>
                        <Icon sx={{ color: Colors.ERROR }}>logout</Icon>
                    </ListItemIcon>
                    <ListItemText primary={'Keluar'} sx={{ color: Colors.ERROR }} />
                </ListItemButton>
            </ListItem>
        </List>
    </div>
);

const drawerWidth = 240;
export const SideBar = (props: { window?: any; page: string; title: string }) => {
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [isClosing, setClosing] = React.useState(false);
    const [isLogout, setLogout] = React.useState(false);
    const navigate = useNavigate()

    const handleDrawerClose = () => {
        setClosing(true);
        setMobileOpen(false);
    };

    const handleDrawerTransitionEnd = () => {
        setClosing(false);
    };

    const handleDrawerToggle = () => {
        if (!isClosing) {
            setMobileOpen(!mobileOpen);
        }
    };

    const onLogout = React.useCallback(() => {
        setLogout(true)
    }, [isLogout])

    const container = props.window !== undefined ? () => props.window().document.body : undefined;

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar
                position="fixed"
                color='primary'
                sx={{
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    ml: { sm: `${drawerWidth}px` },
                }}
            >
                {
                    isMobile ?
                        <Toolbar>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                edge="start"
                                onClick={handleDrawerToggle}
                                sx={{ mr: 2, display: { sm: 'none' } }}
                            >
                                <MenuIcon />
                            </IconButton>
                            <Typography variant="h6" noWrap component="div">
                                {props.title}
                            </Typography>
                        </Toolbar>
                        :
                        <Toolbar>
                         <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} width={'100%'}>
                            <Typography variant="h6" noWrap component="div">
                                {props.title}
                            </Typography>
                            <Typography variant="body1" noWrap component="div">
                                {moment().format('dddd, DD MMM YYYY')}
                            </Typography>
                         </Stack>
                        </Toolbar>
                }
            </AppBar>
            <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                aria-label="mailbox folders"
            >
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onTransitionEnd={handleDrawerTransitionEnd}
                    onClose={handleDrawerClose}
                    ModalProps={{
                        keepMounted: true,
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    <SideContent page={props.page} navigate={navigate} onLogout={onLogout} />
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', sm: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                    open
                >
                    <SideContent page={props.page} navigate={navigate} onLogout={onLogout} />
                </Drawer>
            </Box>
            <LogoutDialog isOpen={isLogout} onClose={() => setLogout(false)} navigate={navigate} />
        </Box>
    );
}
