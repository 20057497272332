import { AxiosMethod } from "../utils/interceptors";

export function HTTPAuthRefresh(props: { email: string }): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosMethod().post("/auth/refresh", {
        email: props.email,
      });
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPAuthLogin(props: {
  email: string;
  password: string;
}): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosMethod().post("/auth/login", {
        email: props.email,
        password: props.password,
      });
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPAuthRegister(props: {
  username: string;
  email: string;
  phone: string;
  position: string;
  address: string;
  password: string;
  shift: any[];
}): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosMethod().post("/auth/register", {
        username: props.username,
        email: props.email,
        phone: props.phone,
        position: props.position,
        address: props.address,
        password: props.password,
        shift: props.shift,
        level: 'staff',
      });
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}
