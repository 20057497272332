import { AxiosMethod } from "../utils/interceptors";

export function HTTPGetActivities(props: { date: string }): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosMethod().post(`/activity/get-all`, {
        date: props.date
      });
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPGetActivitybyUser(props: { email: string; activity_id: number }): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosMethod().post("/activity/get", {
        email: props.email,
        activity_id: props.activity_id
      });
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}
