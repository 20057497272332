export const JobList = [
  "Frontend Developer",
  "Backend Developer",
  "UI/UX Designer",
  "IT Support",
  "Project Manager",
  "Graphic Designer",
  "Market Maker",
];

export const ShiftList = [
  `{"clockIn":"08:00","clockOut":"16:00"}`,
  `{"clockIn":"16:00","clockOut":"00:00"}`,
];
