import { BoxShadow, Colors } from "../../utils/styles";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Icon, Slide, Stack, Typography } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import React from "react";
import { isMobile } from "react-device-detect";
import { HTTPConfirmMail } from "../../servers/mail";
import { toast } from "react-toastify";
import { IMail } from "../../interfaces";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const RejectMailDialog = (props: { isOpen: boolean; onClose: any; data: any; onFinish: any }) => {
    // const [props.data, setMailData] = React.useState<IMail>({
    //     _id: '',
    //     user_email: '',
    //     username: '',
    //     date: '',
    //     type: '',
    //     status: '',
    //     reason: '',
    //     overtime: '',
    //     permit_type: '',
    //     leave_date: '',
    // })
    const [isReady, setReady] = React.useState(false)
    const [isLoading, setLoading] = React.useState(false)

    const onClose = () => {
        props.onClose()
        // setMailData({
        //     _id: '',
        //     user_email: '',
        //     username: '',
        //     date: '',
        //     type: '',
        //     status: '',
        //     reason: '',
        //     overtime: '',
        //     permit_type: '',
        //     leave_date: '',
        // })
    }

    const onConfirm = async () => {
        setLoading(true)
        try {
            await HTTPConfirmMail({
                date: props.data.type === 'leave' ? props.data.leave_date : props.data.date,
                email: props.data.user_email,
                mail_id: props.data._id,
                status: 'reject',
                type: props.data.type
            })
            onClose()
            props.onFinish()
            toast.success('Berhasil menolak pengajuan!')
        } catch (error) {
            console.log(error)
            toast.error('Terjadi Kesalahan!')
        }
        setLoading(false)
    }

    // React.useEffect(() => {
    //     if (props.isOpen === true) {
    //         setReady(false)
    //         setMailData(props.data)
    //         setReady(true)
    //     }
    // }, [props.isOpen])

    return (
        <React.Fragment>
            {
                props.isOpen === true ?
                    <Dialog
                        open={props.isOpen}
                        onClose={props.onClose}
                        keepMounted
                        disableScrollLock
                        TransitionComponent={Transition}
                        PaperProps={{
                            sx: {
                                width: '100%',
                                maxWidth: isMobile ? '100vw' : '30vw',
                                borderRadius: 5,
                            }
                        }}
                    >
                        <DialogContent>
                            <Stack direction={'column'} spacing={1} width={'100%'} alignItems={'center'} paddingY={1}>
                                <Icon sx={{ color: Colors.ERROR, fontSize: 50 }}>block</Icon>
                                <Typography variant="body1" color={Colors.SUBMAIN} sx={{ textAlign: 'center' }}>Tolak pengajuan ini?</Typography>
                                <br />
                                <Stack direction={'row'} justifyContent={'center'} alignItems={'center'} spacing={1} width={'100%'}>
                                    <Button
                                        disabled={isLoading}
                                        color={'primary'}
                                        variant={'text'}
                                        onClick={props.onClose}
                                        sx={{ textTransform: 'capitalize', borderRadius: 3 }}
                                    >Batal</Button>
                                    <Button
                                        disabled={isLoading}
                                        color={'error'}
                                        variant={'contained'}
                                        onClick={onConfirm}
                                        sx={{ textTransform: 'capitalize', borderRadius: 3 }}
                                    >Tolak</Button>
                                </Stack>
                            </Stack>
                        </DialogContent>
                    </Dialog>
                    : null
            }
        </React.Fragment>
    )
}