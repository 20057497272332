import React from "react";
import Page from "./routerTitle";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { RouterInit } from "../utils/routerInit";

import LoginPage from "../pages/auth/page";
import StaffPage from "../pages/staff/page";
import MailPage from "../pages/mail/page";

const PageRoutes = () => {
    return (
        <Router>
            <RouterInit />
            <Routes>
                <Route path="/" element={<Page title="Login | DRX Work"><LoginPage /></Page>} />
                <Route path="/staff" element={<Page title="Karyawan | DRX Work"><StaffPage /></Page>} />
                <Route path="/mail" element={<Page title="Pengajuan | DRX Work"><MailPage /></Page>} />
            </Routes>
        </Router>
    );
};

export default PageRoutes;