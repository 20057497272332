import { Assets } from "../../assets";
import { Colors } from "../../utils/styles";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Icon, IconButton, MenuItem, Select, Slide, Stack, TextField, Typography } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import React from "react";
import { isMobile } from "react-device-detect";
import { toast } from "react-toastify";
import { HTTPAuthRegister } from "../../servers/auth";
import { JobList, ShiftList } from "./data";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const AddUserDialog = (props: { isOpen: boolean; onClose: any; onFinish: any }) => {
    const [isLoading, setLoading] = React.useState(false)
    const [showPass, setShowPass] = React.useState(true)
    const [Username, setUsername] = React.useState("")
    const [Email, setEmail] = React.useState("")
    const [Phone, setPhone] = React.useState("")
    const [Address, setAddress] = React.useState("")
    const [SelectedJob, setSelectedJob] = React.useState('Frontend Developer')
    const [SelectedShift, setSelectedShift] = React.useState(`{"clockIn":"08:00","clockOut":"16:00"}`)
    const [Password, setPassword] = React.useState("")
    const [ConfirmPass, setConfirmPass] = React.useState("")

    const onClose = () => {
        props.onClose()
        setUsername("")
        setEmail("")
        setPhone("")
        setAddress("")
        setSelectedJob("Frontend Developer")
        setSelectedShift(`{"clockIn":"08:00","clockOut":"16:00"}`)
        setPassword("")
        setConfirmPass("")
    }

    const onSubmit = async () => {
        setLoading(true)
        if (
            Username.length === 0 ||
            Email.length === 0 ||
            Phone.length === 0 ||
            Address.length === 0 ||
            SelectedJob.length === 0 ||
            SelectedShift.length === 0 ||
            Password.length === 0
        ) {
            toast.error("Pastikan semua form terisi!")
        } else {
            try {
                if (Password === ConfirmPass) {
                    const Shift = JSON.parse(SelectedShift)
                    await HTTPAuthRegister({
                        username: Username,
                        email: Email,
                        phone: Phone,
                        position: SelectedJob,
                        address: Address,
                        password: Password,
                        shift: [Shift.clockIn, Shift.clockOut],
                    })
                    toast.success("Berhasil mendaftarkan karyawan!")
                    onClose()
                    props.onFinish()
                } else {
                    toast.error("Password tidak sesuai!")
                }
            } catch (error) {
                console.log(error)
                toast.error('Terjadi kesalahan ketika menambahkan karyawan!')
            }
        }
        setLoading(false)
    }

    return (
        <React.Fragment>
            <Dialog
                open={props.isOpen}
                onClose={onClose}
                keepMounted
                disableScrollLock
                TransitionComponent={Transition}
                PaperProps={{
                    sx: {
                        width: '100%',
                        maxWidth: isMobile ? '100vw' : '30vw',
                        borderRadius: 5,
                    }
                }}
            >
                <DialogTitle sx={{ color: Colors.SUBMAIN }}>Tambah Karyawan</DialogTitle>
                <DialogContent>
                    <Stack direction={'column'} spacing={3} width={'100%'}>
                        <TextField
                            placeholder="Nama"
                            type={'text'}
                            variant="outlined"
                            size={'small'}
                            sx={{ backgroundColor: '#ffffff', borderRadius: 3 }}
                            value={Username}
                            onChange={(text) => setUsername(text.target.value)}
                            InputProps={{
                                sx: { color: Colors.SUBMAIN, borderRadius: 3 },
                                startAdornment: (<Icon fontSize={'small'} sx={{ marginRight: 1 }}>account_circle</Icon>)
                            }}
                        />
                        <TextField
                            placeholder="Email"
                            type={'email'}
                            variant="outlined"
                            size={'small'}
                            sx={{ backgroundColor: '#ffffff', borderRadius: 3 }}
                            value={Email}
                            onChange={(text) => setEmail(text.target.value)}
                            InputProps={{
                                sx: { color: Colors.SUBMAIN, borderRadius: 3 },
                                startAdornment: (<Icon fontSize={'small'} sx={{ marginRight: 1 }}>email</Icon>)
                            }}
                        />
                        <TextField
                            placeholder="Nomor Telepon"
                            type={'number'}
                            variant="outlined"
                            size={'small'}
                            sx={{ backgroundColor: '#ffffff', borderRadius: 3 }}
                            value={Phone}
                            onChange={(text) => setPhone(text.target.value)}
                            InputProps={{
                                sx: { color: Colors.SUBMAIN, borderRadius: 3 },
                                startAdornment: (<Icon fontSize={'small'} sx={{ marginRight: 1 }}>phone</Icon>)
                            }}
                        />
                        <Select
                            value={SelectedJob}
                            onChange={(value) => setSelectedJob(value.target.value)}
                            displayEmpty
                            size={'small'}
                            startAdornment={(<Icon fontSize={'small'} sx={{ marginRight: 1, color: Colors.SUBMAIN }}>work</Icon>)}
                            sx={{ backgroundColor: '#ffffff', borderRadius: 3 }}
                            inputProps={{
                                sx: { color: Colors.SUBMAIN, borderRadius: 3 },
                            }}
                        >
                            {
                                JobList.map((item, index) => (
                                    <MenuItem value={item} key={index}>{item}</MenuItem>
                                ))
                            }
                        </Select>
                        <Select
                            value={SelectedShift}
                            onChange={(value) => setSelectedShift(value.target.value)}
                            displayEmpty
                            size={'small'}
                            startAdornment={(<Icon fontSize={'small'} sx={{ marginRight: 1, color: Colors.SUBMAIN }}>alarm</Icon>)}
                            sx={{ backgroundColor: '#ffffff', borderRadius: 3 }}
                            inputProps={{
                                sx: { color: Colors.SUBMAIN, borderRadius: 3 },
                            }}
                        >
                            {
                                ShiftList.map((item, index) => (
                                    <MenuItem value={item} key={index}>{JSON.parse(item).clockIn + ' - ' + JSON.parse(item).clockOut}</MenuItem>
                                ))
                            }
                        </Select>
                        <TextField
                            placeholder="Alamat"
                            variant="outlined"
                            type={'text'}
                            size={'small'}
                            sx={{ backgroundColor: '#ffffff', borderRadius: 3 }}
                            multiline
                            rows={5}
                            value={Address}
                            onChange={(text) => setAddress(text.target.value)}
                            InputProps={{
                                sx: { color: Colors.SUBMAIN, borderRadius: 3, alignItems: 'flex-start' },
                                startAdornment: (<Icon fontSize={'small'} sx={{ marginRight: 1 }}>place</Icon>)
                            }}
                        />
                        <TextField
                            type={showPass ? 'password' : 'text'}
                            placeholder="Password"
                            variant="outlined"
                            size={'small'}
                            sx={{ backgroundColor: '#ffffff', borderRadius: 3 }}
                            value={Password}
                            onChange={(text) => setPassword(text.target.value)}
                            InputProps={{
                                sx: { color: Colors.SUBMAIN, borderRadius: 3 },
                                startAdornment: (<Icon fontSize={'small'} sx={{ marginRight: 1 }}>lock</Icon>),
                                endAdornment: (
                                    <IconButton onClick={() => setShowPass(!showPass)}>
                                        <Icon fontSize={'medium'} sx={{ color: Colors.SUBMAIN }}>{showPass ? 'visibility' : 'visibility_off'}</Icon>
                                    </IconButton>
                                )
                            }}
                        />
                        <TextField
                            type={showPass ? 'password' : 'text'}
                            placeholder="Konfirmasi Password"
                            variant="outlined"
                            size={'small'}
                            sx={{ backgroundColor: '#ffffff', borderRadius: 3 }}
                            value={ConfirmPass}
                            onChange={(text) => setConfirmPass(text.target.value)}
                            InputProps={{
                                sx: { color: Colors.SUBMAIN, borderRadius: 3 },
                                startAdornment: (<Icon fontSize={'small'} sx={{ marginRight: 1 }}>lock</Icon>),
                                endAdornment: (
                                    <IconButton onClick={() => setShowPass(!showPass)}>
                                        <Icon fontSize={'medium'} sx={{ color: Colors.SUBMAIN }}>{showPass ? 'visibility' : 'visibility_off'}</Icon>
                                    </IconButton>
                                )
                            }}
                        />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button
                        disabled={isLoading}
                        color={'error'}
                        variant={'text'}
                        onClick={onClose}
                        sx={{ textTransform: 'capitalize', borderRadius: 3 }}
                    >Batal</Button>
                    <Button
                        disabled={isLoading}
                        color={'primary'}
                        variant={'contained'}
                        onClick={onSubmit}
                        sx={{ textTransform: 'capitalize', borderRadius: 3 }}
                    >Simpan</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}