import { Assets } from "../../assets";
import { Colors } from "../../utils/styles";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Icon, IconButton, MenuItem, Select, Slide, Stack, TextField, Typography } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import React from "react";
import { isMobile } from "react-device-detect";
import { HTTPConfirmMail } from "../../servers/mail";
import { IMail } from "../../interfaces";
import { toast } from "react-toastify";
import moment from "moment";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const DetailMailDialog = (props: { isOpen: boolean; onClose: any; data: IMail; onFinish: any }) => {
    // const [props.data, setMailData] = React.useState<IMail>({
    //     _id: '',
    //     user_email: '',
    //     username: '',
    //     date: '',
    //     type: '',
    //     status: '',
    //     reason: '',
    //     overtime: '',
    //     permit_type: '',
    //     leave_date: '',
    // })
    const [isReady, setReady] = React.useState(false)
    const [isLoading, setLoading] = React.useState(false)

    const onClose = () => {
        props.onClose()
        // setMailData({
        //     _id: '',
        //     user_email: '',
        //     username: '',
        //     date: '',
        //     type: '',
        //     status: '',
        //     reason: '',
        //     overtime: '',
        //     permit_type: '',
        //     leave_date: '',
        // })
    }

    const onConfirm = async (status: string) => {
        setLoading(true)
        try {
            await HTTPConfirmMail({
                date: props.data.type === 'leave' ? props.data.leave_date : props.data.date,
                email: props.data.user_email,
                mail_id: props.data._id,
                status,
                type: props.data.type
            })
            onClose()
            props.onFinish()
            if (status === 'accept') {
                toast.success('Berhasil menerima pengajuan!')
            } else {
                toast.success('Berhasil menolak pengajuan!')
            }
        } catch (error) {
            console.log(error)
            toast.error('Terjadi Kesalahan!')
        }
        setLoading(false)
    }

    // React.useEffect(() => {
    //     if (props.isOpen === true) {
    //         setReady(false)
    //         console.log(props.data)
    //         // setMailData(props.data)
    //         setReady(true)
    //     }
    // }, [props.isOpen])

    return (
        <React.Fragment>
            {
                props.isOpen === true ?
                    <Dialog
                        open={props.isOpen}
                        onClose={onClose}
                        keepMounted
                        disableScrollLock
                        TransitionComponent={Transition}
                        PaperProps={{
                            sx: {
                                width: '100%',
                                maxWidth: isMobile ? '100vw' : '30vw',
                                borderRadius: 5,
                            }
                        }}
                    >

                        <DialogTitle>
                            {
                                props.data.type === 'overtime' ?
                                    <Stack direction={'row'} alignItems={'center'} spacing={1}>
                                        <Icon fontSize={'medium'} sx={{ color: Colors.INFO }}>schedule</Icon>
                                        <Typography variant="h6" color={Colors.INFO}>Pengajuan Lembur</Typography>
                                    </Stack>
                                    :
                                    props.data.type === 'permit' ?
                                        <Stack direction={'row'} alignItems={'center'} spacing={1}>
                                            <Icon fontSize={'medium'} sx={{ color: Colors.WARNING }}>description</Icon>
                                            <Typography variant="h6" color={Colors.WARNING}>Pengajuan Izin</Typography>
                                        </Stack>
                                        :
                                        props.data.type === 'leave' ?
                                            <Stack direction={'row'} alignItems={'center'} spacing={1}>
                                                <Icon fontSize={'medium'} sx={{ color: Colors.ERROR }}>beach_access</Icon>
                                                <Typography variant="h6" color={Colors.ERROR}>Pengajuan Cuti</Typography>
                                            </Stack>
                                            :
                                            <Stack direction={'row'} alignItems={'center'} spacing={1}>
                                                <Icon fontSize={'medium'} sx={{ color: Colors.SECONDARY }}>question_mark</Icon>
                                                <Typography variant="h6" color={Colors.SECONDARY}>Tanpa Keterangan</Typography>
                                            </Stack>
                            }
                        </DialogTitle>
                        <DialogContent>
                            <Stack direction={'column'} spacing={3} width={'100%'}>
                                <Box sx={{ width: '100%' }}>
                                    <TextField
                                        placeholder="Nama"
                                        type={'text'}
                                        variant="outlined"
                                        size={'small'}
                                        defaultValue={props.data.username}
                                        sx={{ width: '100%', marginBottom: 1 }}
                                        InputProps={{
                                            readOnly: true,
                                            sx: { color: Colors.SUBMAIN, borderRadius: 3 },
                                            startAdornment: (<Icon fontSize={'small'} sx={{ marginRight: 1 }}>account_circle</Icon>)
                                        }}
                                    />
                                    <TextField
                                        placeholder="Tanggal"
                                        type={'text'}
                                        variant="outlined"
                                        size={'small'}
                                        defaultValue={moment(props.data.date).format('dddd, DD MMMM YYYY')}
                                        sx={{ width: '100%' }}
                                        InputProps={{
                                            readOnly: true,
                                            sx: { color: Colors.SUBMAIN, borderRadius: 3 },
                                            startAdornment: (<Icon fontSize={'small'} sx={{ marginRight: 1 }}>calendar_month</Icon>)
                                        }}
                                    />
                                </Box>
                                {
                                    props.data.type === 'overtime' ?
                                        <Box sx={{ width: '100%' }}>
                                            <Typography variant="subtitle2" color={'#000'} sx={{ marginBottom: 1 }}><b>Durasi Lembur</b></Typography>
                                            <TextField
                                                placeholder="Lembur"
                                                type={'text'}
                                                variant="outlined"
                                                size={'small'}
                                                defaultValue={props.data.overtime}
                                                sx={{ width: '100%' }}
                                                InputProps={{
                                                    readOnly: true,
                                                    sx: { color: Colors.SUBMAIN, borderRadius: 3 },
                                                    startAdornment: (<Icon fontSize={'small'} sx={{ marginRight: 1 }}>schedule</Icon>)
                                                }}
                                            />
                                        </Box>
                                        :
                                        props.data.type === 'permit' ?
                                            <Box sx={{ width: '100%' }}>
                                                <Typography variant="subtitle2" color={'#000'} sx={{ marginBottom: 1 }}><b>Jenis Izin</b></Typography>
                                                <TextField
                                                    placeholder="Lembur"
                                                    type={'text'}
                                                    variant="outlined"
                                                    size={'small'}
                                                    defaultValue={
                                                        props.data.permit_type === 'sick' ? 'Sakit' :
                                                            props.data.permit_type === 'leave' ? 'Meninggalkan Pekerjaan' :
                                                                'Tidak Bekerja'
                                                    }
                                                    sx={{ width: '100%' }}
                                                    InputProps={{
                                                        readOnly: true,
                                                        sx: { color: Colors.SUBMAIN, borderRadius: 3 },
                                                        startAdornment: (<Icon fontSize={'small'} sx={{ marginRight: 1 }}>article</Icon>)
                                                    }}
                                                />
                                            </Box>
                                            :
                                            props.data.type === 'leave' ?
                                                <Box sx={{ width: '100%' }}>
                                                    <Typography variant="subtitle2" color={'#000'} sx={{ marginBottom: 1 }}><b>Tanggal Cuti</b></Typography>
                                                    <TextField
                                                        placeholder="Lembur"
                                                        type={'text'}
                                                        variant="outlined"
                                                        size={'small'}
                                                        defaultValue={moment(props.data.leave_date).format('DD MMMM YYYY')}
                                                        sx={{ width: '100%' }}
                                                        InputProps={{
                                                            readOnly: true,
                                                            sx: { color: Colors.SUBMAIN, borderRadius: 3 },
                                                            startAdornment: (<Icon fontSize={'small'} sx={{ marginRight: 1 }}>today</Icon>)
                                                        }}
                                                    />
                                                </Box>
                                                :
                                                null
                                }
                                <Box sx={{ width: '100%' }}>
                                    <Typography variant="subtitle2" color={'#000'} sx={{ marginBottom: 1 }}><b>Alasan</b></Typography>
                                    <TextField
                                        placeholder="Alamat"
                                        variant="outlined"
                                        type={'text'}
                                        size={'small'}
                                        defaultValue={props.data.reason}
                                        multiline
                                        sx={{ width: '100%' }}
                                        InputProps={{
                                            readOnly: true,
                                            sx: { color: Colors.SUBMAIN, borderRadius: 3, width: '100%' },
                                        }}
                                    />
                                </Box>
                            </Stack>
                        </DialogContent>
                        {
                            props.data.status === 'waiting' ?
                                <DialogActions>
                                    <Button
                                        disabled={isLoading}
                                        color={'primary'}
                                        variant={'text'}
                                        onClick={onClose}
                                        sx={{ textTransform: 'capitalize', borderRadius: 3 }}
                                    >Batal</Button>
                                    <Button
                                        disabled={isLoading}
                                        color={'error'}
                                        size={'medium'}
                                        sx={{ borderRadius: 2, color: '#fff', textTransform: 'capitalize' }}
                                        variant={'contained'}
                                        onClick={() => onConfirm('reject')}
                                        startIcon={(<Icon fontSize={'small'} sx={{ color: '#fff' }}>block</Icon>)}
                                    >Tolak</Button>
                                    <Button
                                        disabled={isLoading}
                                        color={'success'}
                                        size={'medium'}
                                        sx={{ borderRadius: 2, color: '#fff', textTransform: 'capitalize' }}
                                        variant={'contained'}
                                        onClick={() => onConfirm('accept')}
                                        startIcon={(<Icon fontSize={'small'} sx={{ color: '#fff' }}>check_circle_outline</Icon>)}
                                    >Setujui</Button>
                                </DialogActions>
                                :
                                <DialogActions>
                                    <Button
                                        color={'primary'}
                                        variant={'text'}
                                        onClick={onClose}
                                        sx={{ textTransform: 'capitalize', borderRadius: 3 }}
                                    >Tutup</Button>
                                </DialogActions>
                        }
                    </Dialog>
                    : null
            }
        </React.Fragment>
    )
}