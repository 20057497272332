import { AxiosMethod } from "../utils/interceptors";

export function HTTPGetMails(): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosMethod().get(`/mail/get-all`);
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPConfirmMail(props: {
  mail_id: string;
  status: string;
  email: string;
  date: string;
  type: string;
}): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosMethod().put(`/mail/confirm`, {
        mail_id: props.mail_id,
        status: props.status,
        email: props.email,
        date: props.date,
        type: props.type,
      });
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}
