import { BoxShadow, Colors } from "../../utils/styles";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Icon, Slide, Stack, Typography } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import React from "react";
import { isMobile } from "react-device-detect";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const LogoutDialog = (props: { isOpen: boolean; onClose: any; navigate: any }) => {
    const onLogout = () => {
        localStorage.setItem('auth', '')
        localStorage.setItem('isLogin', 'false')
        props.navigate('/')
    }
    
    return (
        <React.Fragment>
            <Dialog
                open={props.isOpen}
                onClose={props.onClose}
                keepMounted
                disableScrollLock
                TransitionComponent={Transition}
                PaperProps={{
                    sx: {
                        width: '100%',
                        maxWidth: isMobile ? '100vw' : '30vw',
                        borderRadius: 5,
                    }
                }}
            >
                <DialogContent>
                    <Stack direction={'column'} spacing={1} width={'100%'} alignItems={'center'} paddingY={1}>
                        <Icon sx={{ color: Colors.ERROR, fontSize: 50 }}>logout</Icon>
                        <Typography variant="body1" color={Colors.SUBMAIN} sx={{ textAlign: 'center' }}>Yakin ingin keluar dari akun?</Typography>
                        <br />
                        <Stack direction={'row'} justifyContent={'center'} alignItems={'center'} spacing={1} width={'100%'}>
                            <Button
                                color={'primary'}
                                variant={'text'}
                                onClick={props.onClose}
                                sx={{ textTransform: 'capitalize', borderRadius: 3 }}
                            >Batal</Button>
                            <Button
                                color={'error'}
                                variant={'contained'}
                                onClick={onLogout}
                                sx={{ textTransform: 'capitalize', borderRadius: 3, color: '#fff' }}
                            >Keluar</Button>
                        </Stack>
                    </Stack>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    )
}