export const CENTER = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

export const BoxShadow = {
  boxShadow: "1px 1px 5px 0px rgba(0,0,0,0.33)",
};

export const TextGradient = {
  background: "linear-gradient(to right, #060401 0%, #F5A725 100%)",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
};

export const Colors = {
  MAIN: "#4d80ef",
  SUBMAIN: "#1e3a8a",
  PRIMARY: "#06b6d4",
  SECONDARY: "#737373",
  SUCCESS: "#22c55e",
  ERROR: "#ef4444",
  WARNING: "#f97316",
  INFO: "#0ea5e9",
};
